
import {defineComponent} from 'vue';
// import Measure from "@/serviceProviders/model/Measure";
import ServiceProvider from "@/serviceProviders/model/ServiceProvider";
import {Trade} from "@/serviceProviders/model/trade";
import CustomButton from "@/common/button/CustomButton.vue";
import {quickcheckPropertyStore} from "@/quickcheck/model/currentQuickcheckProperty";
import {messagesStore} from "@/messaging/model/messagesStore";
import {userInfoStore} from "@/user/model/currentUserInfo";

interface Data {
  name: string,
  address: string,
  openingTimes: string,
  additionalInformation: string,
  serviceProvider: ServiceProvider | null,
  usersServiceProvider: ServiceProvider | null,
  trades: Trade[] | null,
  activeTrades: string[],
  showEditButton: boolean,
  showRequestButton: boolean,
  tradesMap: Map<string, string>,
  isUserLoggedIn: boolean,
}

export default defineComponent({
  components: {CustomButton},
  setup() {
    const messageStore = messagesStore();
    return {messageStore}
  },
  data(): Data {
    return {
      name: '',
      address: '',
      openingTimes: '',
      additionalInformation: '',
      serviceProvider: null,
      usersServiceProvider: null,
      trades: [],
      activeTrades: [],
      showEditButton: false,
      showRequestButton: false,
      tradesMap: new Map<string, string>(),
      isUserLoggedIn: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === 'service-provider-details' && to.name === from.name) {
        this.fetchServiceProvider();
      }
    }
  },
  methods: {
    async fetchCurrentUser() {
      const user = await this.$ports.user.getAuthenticatedUser();
      if (user !== null) {
        this.isUserLoggedIn = true;
      }
    },
    async fetchAvailableTrades() {
      this.trades = await this.$ports.serviceProviders.getAvailableTrades() as Trade[];

      if (this.trades) {
        for (let i = 0; i < this.trades.length; i++) {
          this.tradesMap.set(this.trades[i].key, this.trades[i].name);
        }
      }
    },
    async fetchServiceProvider() {
      const currentServiceProviderKey = this.$route.params.key as string;
      if (currentServiceProviderKey !== '') {
        this.serviceProvider = await this.$ports.serviceProviders.getServiceProviderByKey(currentServiceProviderKey);
      } else {
        await this.editServiceProviderProfile();
      }

      if ( this.serviceProvider !== null) {
        this.name = this.serviceProvider.name;
        this.address = this.serviceProvider.address;
        this.openingTimes = this.serviceProvider.openingTimes;
        this.activeTrades = this.serviceProvider.tradeKeys;
        this.additionalInformation = this.serviceProvider.additionalInformation;
        this.tradeKeysToNames();

        this.usersServiceProvider = await this.$ports.serviceProviders.getServiceProviderByUserKey();
        if (this.usersServiceProvider !== null && this.serviceProvider.key === this.usersServiceProvider.key) {
          this.showEditButton = true;
        } else {
          if (this.isUserLoggedIn) {
            this.showRequestButton = true;
          }
        }
      }
    },
    tradeKeysToNames(): void {
      for (let i = 0; i < this.activeTrades.length; i++) {
        this.activeTrades[i] = this.tradesMap.get(this.activeTrades[i])!;
      }
    },
    async editServiceProviderProfile(): Promise<void> {
      await this.$router.push({
        name: 'service-provider-create',
      });
    },
    async goToMessages() {
      this.messageStore.updateIsNewMessage(true);
      if (this.serviceProvider !== null) {
        this.messageStore.updateRecipient(this.serviceProvider.name);
        this.messageStore.updateReceivingUserKey(this.serviceProvider.userKey);
      }

      await this.$router.push({
        name: 'messages',
      });
    }
  },
  async mounted() {
    await this.fetchCurrentUser();
    await this.fetchAvailableTrades();
    await this.fetchServiceProvider();
  }
});
